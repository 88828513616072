<div class="widget-container">
  <span class="header">{{ 'switchAssetTree.dialog.treeSeletion.title' | translate }}</span>
  <app-tree-selector
    [trees]="trees"
    [activeTreeId]="selectedTree?.uuid"
    (treeSelected)="selectTree($event)"
    maxHeight="200px"
    [disabled]="uiEnabled() === false"
  ></app-tree-selector>

  <div *ngIf="onPreflightCheckUpdate | async as preflightCheck">
    <div class="issue-details">
      <app-callout *ngIf="preflightCheck.moveProcessFailed === true" severity="error">
        <div class="flex-col">
          {{ 'switchAssetTree.dialog.issues.moveProcessFailed' | translate }}
          <button
            class="item-align-right ui-button-bare no-padding"
            pButton
            type="button"
            label="{{ 'switchAssetTree.dialog.refreshButton' | translate }}"
            icon="pi pi-refresh icon-label-button"
            (click)="checkMoveCanBeDone()"
          ></button>
        </div>
      </app-callout>
      <app-callout *ngIf="preflightCheck.treeLoadError === true" severity="error">
        <div class="flex-col">
          {{ 'switchAssetTree.dialog.issues.treeLoadFail' | translate }}
          <button
            class="item-align-right ui-button-bare no-padding"
            pButton
            type="button"
            label="{{ 'switchAssetTree.dialog.reloadTrees' | translate }}"
            icon="pi pi-refresh icon-label-button"
            (click)="loadAvailableTrees()"
          ></button>
        </div>
      </app-callout>
      <app-callout *ngIf="preflightCheck.preflightCheckError === true" severity="error">
        <div class="flex-col">
          {{ 'switchAssetTree.dialog.issues.preflightCheckError' | translate }}
          <button
            class="item-align-right ui-button-bare no-padding"
            pButton
            type="button"
            label="{{ 'switchAssetTree.dialog.refreshButton' | translate }}"
            icon="pi pi-refresh icon-label-button"
            (click)="checkMoveCanBeDone()"
          ></button>
        </div>
      </app-callout>
      <app-callout *ngIf="preflightCheck.isManagedNode === true" severity="info">
        <div [innerHTML]="'switchAssetTree.dialog.issues.noServiceTreesAllowed' | translate"></div>
      </app-callout>
      <app-callout *ngIf="preflightCheck.insufficientPermissions === true" severity="info">
        <div
          [innerHTML]="'switchAssetTree.dialog.issues.insufficientPermissions' | translate"
        ></div>
      </app-callout>
      <app-callout *ngIf="preflightCheck.duplicateDevicesFound" severity="warn">
        <div class="flex-col">
          {{ 'switchAssetTree.dialog.issues.duplicateDevices.duplicateDevicesFound' | translate }}
          <div class="table-frame">
            <table class="borderlessTable">
              <tr>
                <th>
                  {{
                    'switchAssetTree.dialog.issues.duplicateDevices.duplicateDevTable.sourceTree'
                      | translate
                  }}
                </th>
                <th></th>
                <th>
                  {{
                    'switchAssetTree.dialog.issues.duplicateDevices.duplicateDevTable.dstTree'
                      | translate
                  }}
                </th>
              </tr>
              <tr *ngFor="let duplicateDevice of preflightCheck.duplicateDevicesFound.value">
                <td>
                  <a class="image-link" (click)="openAssetInNewTab(duplicateDevice.srcDevice.uuid)">
                    <span class="pi pi-external-link"></span>
                    <span class="link-text">
                      {{
                        duplicateDevice.srcDevice.name ||
                          duplicateDevice.srcDevice.productName ||
                          ('assetTypes.device' | translate)
                      }}
                    </span>
                  </a>
                </td>
                <td>
                  <i class="pi pi-arrow-right" style="font-size: 20px"></i>
                </td>
                <td>
                  <a class="image-link" (click)="openAssetInNewTab(duplicateDevice.dstDevice.uuid)">
                    <span class="pi pi-external-link"></span>
                    <span class="link-text">
                      {{
                        duplicateDevice.dstDevice.name ||
                          duplicateDevice.dstDevice.productName ||
                          ('assetTypes.device' | translate)
                      }}</span
                    >
                  </a>
                </td>
              </tr>
            </table>
          </div>
          <div
            [innerHTML]="
              'switchAssetTree.dialog.issues.duplicateDevices.duplicateDevicesResolve' | translate
            "
          ></div>
          <button
            class="item-align-right ui-button-bare no-padding"
            pButton
            type="button"
            label="{{ 'switchAssetTree.dialog.refreshButton' | translate }}"
            icon="pi pi-refresh icon-label-button"
            (click)="checkMoveCanBeDone()"
          ></button>
        </div>
      </app-callout>
      <app-callout *ngIf="preflightCheck.iccDeviceClusterIncomplete?.length" severity="warn">
        <div class="flex-col">
          <div>
            <span
              [innerHtml]="
                'switchAssetTree.dialog.issues.iccDeviceClusterIncomplete.header' | translate
              "
            ></span>
          </div>
          <div class="table-frame">
            <table class="borderlessTable">
              <tr>
                <th>
                  {{
                    'switchAssetTree.dialog.issues.iccDeviceClusterIncomplete.datatable.moved-devices'
                      | translate
                  }}
                </th>
                <th></th>
                <th>
                  {{
                    'switchAssetTree.dialog.issues.iccDeviceClusterIncomplete.datatable.missed-devices'
                      | translate
                  }}
                </th>
              </tr>
              <tr *ngFor="let incompleteCluster of preflightCheck.iccDeviceClusterIncomplete">
                <td class="vertical-spacing">
                  <div class="flex-row" *ngFor="let movedDevice of incompleteCluster.devicesMoved">
                    <a
                      class="image-link"
                      [ngClass]="{ reducedMaxWidth: movedDevice.isGatewayDevice }"
                      (click)="openAssetInNewTab(movedDevice.uuid)"
                    >
                      <span class="pi pi-external-link"></span>
                      <span class="link-text">
                        {{
                          movedDevice.name ||
                            movedDevice.productName ||
                            ('assetTypes.device' | translate)
                        }}
                      </span>
                    </a>
                    <div class="gateway-device" *ngIf="movedDevice.isGatewayDevice === true">
                      <span>{{ 'icc.gateway-device' | translate }}</span>
                    </div>
                  </div>
                </td>
                <td class="vertical-spacing">
                  <i class="pi pi-link" style="font-size: 20px"></i>
                </td>
                <td class="vertical-spacing">
                  <div
                    class="flex-row"
                    *ngFor="let missedDevice of incompleteCluster.devicesMissing"
                  >
                    <a
                      class="image-link"
                      [ngClass]="{ reducedMaxWidth: missedDevice.isGatewayDevice }"
                      (click)="openAssetInNewTab(missedDevice.uuid)"
                    >
                      <span class="pi pi-external-link"></span>
                      <span class="link-text">
                        {{
                          missedDevice.name ||
                            missedDevice.productName ||
                            ('assetTypes.device' | translate)
                        }}
                      </span>
                    </a>
                    <div class="gateway-device" *ngIf="missedDevice.isGatewayDevice === true">
                      <span>{{ 'icc.gateway-device' | translate }}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          {{ 'switchAssetTree.dialog.issues.iccDeviceClusterIncomplete.footer' | translate }}
          <button
            class="item-align-right ui-button-bare no-padding"
            pButton
            type="button"
            label="{{ 'switchAssetTree.dialog.refreshButton' | translate }}"
            icon="pi pi-refresh icon-label-button"
            (click)="checkMoveCanBeDone()"
          ></button>
        </div>
      </app-callout>
    </div>
  </div>

  <div class="bottom-bar">
    <div class="table">
      <div class="tr">
        <div class="td">
          <div class="flex-row">
            <div class="labeled-element">
              <p-checkbox
                inputId="keep-permissions"
                [binary]="true"
                [disabled]="moveOperationEnabled() === false || hasPermissionMoveRights() === false"
                [(ngModel)]="keepPermissions"
                (onChange)="updatePermissionSelection($event.checked)"
              ></p-checkbox>
              <label
                for="keep-permissions"
                [ngClass]="{
                  disabled: moveOperationEnabled() === false || hasPermissionMoveRights() === false,
                }"
                >{{ 'switchAssetTree.dialog.keep-permissions.title' | translate }}</label
              >
            </div>
            <app-tooltip width="22em">
              <div
                [innerHtml]="'switchAssetTree.dialog.keep-permissions.tooltip' | translate"
              ></div>
            </app-tooltip>
          </div>
        </div>
        <div class="td"></div>
        <div class="td"></div>
      </div>
      <div class="tr">
        <div class="td">
          <div class="labeled-element">
            <p-checkbox
              inputId="keep-history"
              [binary]="true"
              [(ngModel)]="keepHistory"
              [disabled]="moveOperationEnabled() === false"
              (onChange)="updateHistorySelection($event.checked)"
            ></p-checkbox>
            <label for="keep-history" [ngClass]="{ disabled: moveOperationEnabled() === false }">{{
              'switchAssetTree.dialog.keepHistory' | translate
            }}</label>
          </div>
        </div>
        <div class="td"></div>
        <div class="td"></div>
      </div>
      <div class="tr">
        <div class="td"></div>
        <div class="td align-right">
          <button
            id="cancel-button"
            pButton
            type="button"
            class="ui-button-outline"
            label="{{ 'switchAssetTree.dialog.cancel' | translate }}"
            (click)="closeDialog()"
            [disabled]="uiEnabled() === false"
          ></button>
        </div>
        <div class="td align-right">
          <button
            pButton
            type="button"
            class="ui-button-primary"
            label="{{ 'switchAssetTree.dialog.move' | translate }}"
            [disabled]="moveOperationEnabled() === false"
            (click)="moveToSelectedTree()"
          ></button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, effect, Signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AssetDeletedEvent, TreeEventsConsumerService } from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { ToastMessageOptions, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { CalloutComponent } from '../callout/callout.component';
import { AdministratorsModalComponent } from '../modals/administrators-modal/administrators-modal.component';

@Component({
  selector: 'app-asset-deleted-toast',
  templateUrl: './asset-deleted-toast.component.html',
  styleUrls: ['./asset-deleted-toast.component.scss'],
  standalone: true,
  imports: [ToastModule, CalloutComponent, TranslateModule, RouterLink],
})
export class AssetDeletedToastComponent {
  readonly key = 'assetDeletedToast';

  private assetDeleted: Signal<AssetDeletedEvent | undefined>;

  constructor(
    treeEventsConsumerService: TreeEventsConsumerService,
    private messageService: MessageService,
  ) {
    this.assetDeleted = treeEventsConsumerService.assetDeleted;
    effect(() => {
      const event = this.assetDeleted();
      if (!event) {
        return;
      }
      this.onAssetDeletedEvent(event);
    });
  }

  onClose(message: ToastMessageOptions) {
    this.messageService.clear(message.id);
  }

  onAssetDeletedEvent(event: AssetDeletedEvent) {
    const canRestore = event.asset.root.administrable;
    this.messageService.add({
      key: this.key,
      data: { uuid: event.asset.uuid, canRestore, movedToTrash: event.movedToTrash },
      sticky: false,
      life: 60000,
      closable: false,
    });
  }

  showAdmins(event: Event, uuid: string): void {
    event.preventDefault();
    AdministratorsModalComponent.open(uuid, 'permission-insights.restore');
  }
}

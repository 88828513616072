import { Component, Inject } from '@angular/core';
import {
  AssetPermissions,
  Environment,
  ENVIRONMENT,
  permissionsFromString,
} from '@assethub/shared/models';
import { PermissionInsightsComponent } from './permission-insights/permission-insights.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { GroupPermissionsComponent } from './group-permissions/group-permissions.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AppInjector } from '../../utils/app-injector';
import { TabsModule } from 'primeng/tabs';

interface DialogData {
  asset: { uuid: string; permissions: string };
}

@Component({
  selector: 'app-asset-permissions',
  templateUrl: './asset-permissions.component.html',
  styleUrls: ['./asset-permissions.component.scss'],
  standalone: true,
  imports: [
    ButtonModule,
    TranslateModule,
    PermissionInsightsComponent,
    PermissionsComponent,
    GroupPermissionsComponent,
    TabsModule,
  ],
})
export class AssetPermissionsComponent {
  readonly asset: { uuid: string; permissions: string };
  readonly myPermissions: AssetPermissions;
  readonly showGroupPermissions: boolean;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<DialogData>,
    @Inject(ENVIRONMENT) private env: Environment,
    translateService: TranslateService,
  ) {
    this.myPermissions = { admin: false, write: false, read: false };
    this.showGroupPermissions = !!this.env.groupManagement;
    this.config.header = translateService.instant('assetPermissions.title');
    this.config.focusOnShow = false;
    this.config.style = { 'max-width': '50em', 'min-height': '80%', 'max-height': '80%' };
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
    const data = this.config.data;
    if (data) {
      this.asset = data.asset;
      this.myPermissions = permissionsFromString(this.asset.permissions);
    }
  }

  static open(uuid: string, permissions: string) {
    const data: DialogData = { asset: { uuid, permissions } };
    return AppInjector.get(DialogService).open(AssetPermissionsComponent, { data });
  }

  close() {
    this.ref.close();
  }
}

<form [formGroup]="backupSelectionForm">
  <div class="input-container">
    <div class="labeled-element">
      <p-checkbox inputId="backup-all" formControlName="backupAll" [binary]="true"> </p-checkbox>
      <label for="backup-all">{{
        'assetConfigurations.modals.device-backup-create.option-backup-all' | translate
      }}</label>
    </div>
    <div (click)="changeCollapseState()" class="collapsable">
      <span [class]="collapsed ? 'pi pi-chevron-down' : 'pi pi-chevron-up'"></span>
      <span>
        {{
          collapsed
            ? ('assetConfigurations.modals.device-backup-create.custom-selection-collapsed'
              | translate)
            : ('assetConfigurations.modals.device-backup-create.custom-selection-expanded'
              | translate)
        }}</span
      >
    </div>
    <div
      *ngIf="!collapsed"
      class="custom-backup-selection-container"
      formGroupName="backupCustomSelection"
    >
      <div class="app-selection-container">
        <div class="backup-apps-section">
          <div class="labeled-element">
            <p-checkbox
              inputId="backup-apps"
              formControlName="backupApps"
              [binary]="true"
            ></p-checkbox>
            <label for="backup-apps">{{
              'assetConfigurations.modals.device-backup-create.option-backup-apps' | translate
            }}</label>
          </div>
          <app-tooltip width="22em">{{
            'assetConfigurations.modals.device-backup-create.info-backup-apps' | translate
          }}</app-tooltip>
        </div>
        <div class="labeled-element">
          <p-checkbox
            inputId="backup-parameters"
            id="backupParameters"
            formControlName="backupParameters"
            [binary]="true"
          >
          </p-checkbox>
          <label for="backup-parameters">{{
            'assetConfigurations.modals.device-backup-create.option-backup-app-parameter'
              | translate
          }}</label>
        </div>
      </div>
      <div class="labeled-element">
        <p-checkbox
          inputId="backup-public"
          formControlName="backupPublicFolder"
          [binary]="true"
        ></p-checkbox>
        <label for="backup-public">{{
          'assetConfigurations.modals.device-backup-create.option-backup-public-folder' | translate
        }}</label>
      </div>
    </div>

    <span class="p-float-label custom-backup-name">
      <input type="text" pInputText id="backupCustomName" formControlName="backupCustomName" />
      <label for="backupCustomName">{{
        'assetConfigurations.modals.device-backup-create.option-backup-name-label' | translate
      }}</label>
    </span>
    <span class="error" *ngIf="backupNameInputError">{{ backupNameInputError | translate }}</span>
    <app-label-picker
      formControlName="labels"
      (completeMethod)="suggestLabels($event)"
      [suggestions]="suggestions"
      [allowNewLabels]="allowNewLabels"
    ></app-label-picker>
  </div>
</form>
<div class="dialog-footer">
  <button
    pButton
    type="button"
    class="ui-button-outline"
    label="{{ 'buttons.cancel' | translate }}"
    (click)="close()"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    label="{{ 'assetConfigurations.modals.device-backup-create.create-backup' | translate }}"
    (click)="createBackup()"
    [disabled]="!backupSelectionForm.valid"
  ></button>
</div>

export interface TrashListItem {
  uuid: string;
  customName: string;
  type: number;
  productName?: string;

  rootUuid?: string;
  rootCustomName?: string;
  rootType?: number;

  deletedAt: Date;
  purgeAt: Date;

  affectedAssets: number;
  affectedFilesize: number;
}

export interface TrashList {
  items: TrashListItem[];
  total: number;
}

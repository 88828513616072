<div class="labeled-element">
  <p-checkbox
    inputId="clone-recursive"
    [(ngModel)]="recursive"
    [binary]="true"
    [disabled]="loading"
    (onChange)="checkForCloneIssues()"
  ></p-checkbox>
  <label for="clone-recursive" [ngClass]="{ disabled: loading }">{{
    'clone.recursive' | translate
  }}</label>
</div>

<div class="labeled-element">
  <p-checkbox
    inputId="clone-include-custom-uploads"
    [(ngModel)]="includeCustomUploads"
    [binary]="true"
    [disabled]="loading"
  ></p-checkbox>
  <label for="clone-include-custom-uploads" [ngClass]="{ disabled: loading }">{{
    'clone.include-custom-uploads' | translate
  }}</label>
</div>

<div class="labeled-element">
  <p-checkbox
    inputId="clone-include-permissions"
    [(ngModel)]="includePermissions"
    [binary]="true"
    [disabled]="loading"
  ></p-checkbox>
  <label for="clone-include-permissions" [ngClass]="{ disabled: loading }">{{
    'clone.include-permissions' | translate
  }}</label>
</div>

<div *ngIf="loading">
  <div class="overlay"></div>
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>
<div *ngIf="cloneCheckResult.duplicateProducts !== undefined">
  <app-callout severity="warn">
    <span *ngIf="cloneCheckResult.duplicateProducts.count === 1">
      {{ 'clone.warnings.singleDuplicateProduct' | translate }}
    </span>
    <span *ngIf="cloneCheckResult.duplicateProducts.count > 1">
      {{
        'clone.warnings.multipleDuplicateProducts'
          | translate: { duplicateCount: cloneCheckResult.duplicateProducts.count }
      }}
    </span>
  </app-callout>
</div>
<div class="dialog-footer">
  <button
    pButton
    type="button"
    class="ui-button-outline"
    [disabled]="loading"
    (click)="onCancel()"
    label="{{ 'buttons.cancel' | translate }}"
  ></button>
  <button
    pButton
    type="button"
    class="ui-button-primary"
    [disabled]="loading"
    (click)="onOk()"
    label="{{ 'buttons.okay' | translate }}"
  ></button>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServicePermissionModalComponent } from '@assethub/shared/components/modals';
import { UserPermissions } from '@assethub/shared/models';
import { TreeService } from '@assethub/shared/services';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SelectItem, SharedModule } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { SelectModule } from 'primeng/select';
import { FormsModule } from '@angular/forms';
import { TextFieldComponent } from '../text-field/text-field.component';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-permissions-add',
  templateUrl: './permissions-add.component.html',
  styleUrls: ['./permissions-add.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    TextFieldComponent,
    FormsModule,
    SelectModule,
    SharedModule,
    ButtonModule,
    TranslateModule,
  ],
})
export class PermissionsAddComponent {
  @Input() username = '';
  @Input() error = '';
  @Input() narrow = false;
  @Output() add = new EventEmitter<UserPermissions>();

  public permissions = 'r';
  public permissionOptions: SelectItem[] = [
    {
      label: 'assetPermissions.read',
      value: 'r',
    },
    {
      label: 'assetPermissions.write',
      value: 'rw',
    },
    {
      label: 'assetPermissions.admin',
      value: 'rwa',
    },
  ];

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private treeService: TreeService,
  ) {}

  public onNewPermission() {
    const username = this.username.trim();
    if ('' === username) {
      this.setError('assetPermissions.noUsername');
      return;
    }

    const selectedNode = this.treeService.selectedNode();
    if (selectedNode !== undefined && selectedNode.root.crm365) {
      this.dialogService
        .open(ServicePermissionModalComponent, {
          header: this.translateService.instant('assetPermissions.serviceWarning.title'),
          data: {
            group: false,
          },
        })
        .onClose.subscribe(value => {
          if (value !== undefined && value) {
            this.add.emit({ username, permissions: this.permissions });
          }
        });
    } else {
      this.add.emit({ username, permissions: this.permissions });
    }
  }

  public setError(err: string) {
    this.error = err;
  }

  public clear() {
    this.error = '';
    this.username = '';
  }
}

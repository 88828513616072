<form [formGroup]="mainForm" (ngSubmit)="onFormSubmit()">
  <div class="fb-row">
    <h4>{{ 'feedback.title2' | translate }}</h4>
  </div>
  <div class="fb-row">
    {{ 'feedback.rating.label' | translate }}:
    <p-rating [stars]="5" formControlName="rating"></p-rating>
  </div>
  <div class="rating-options">
    <div class="labeled-element">
      <p-radioButton inputId="question" formControlName="reason" value="question"></p-radioButton>
      <label for="question">
        {{ 'feedback.reason.question' | translate }}
      </label>
    </div>

    <div class="labeled-element">
      <p-radioButton inputId="bug" formControlName="reason" value="bug"></p-radioButton>
      <label for="bug">
        {{ 'feedback.reason.bug' | translate }}
      </label>
    </div>
    <div class="labeled-element">
      <p-radioButton inputId="idea" formControlName="reason" value="idea"></p-radioButton>
      <label for="idea">
        {{ 'feedback.reason.idea' | translate }}
      </label>
    </div>
    <div class="labeled-element">
      <p-radioButton inputId="other" formControlName="reason" value="other"></p-radioButton>
      <label for="other">
        {{ 'feedback.reason.other' | translate }}
      </label>
    </div>
  </div>
  <div class="fb-row">
    <span class="p-float-label description">
      <textarea pTextarea id="description" formControlName="description"></textarea>
      <label for="description">{{ 'feedback.description.label' | translate }}</label>
    </span>
  </div>
  <div class="labeled-element">
    <p-checkbox inputId="checkbox-anonymous" formControlName="anonymous" [binary]="true">
    </p-checkbox>
    <label for="checkbox-anonymous">{{ 'feedback.anonymous.label' | translate }}</label>
  </div>
  <div class="dialog-footer">
    <button
      pButton
      type="button"
      class="ui-button-outline"
      label="{{ 'buttons.cancel' | translate }}"
      (click)="onCloseButton()"
    ></button>
    <button
      pButton
      type="button"
      class="ui-button-primary"
      label="{{ 'feedback.button' | translate }}"
      (click)="onFormSubmit()"
      [disabled]="!mainForm.valid || saving"
    ></button>
  </div>
</form>

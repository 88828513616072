@if (myPermissions.admin) {
  @if (showGroupPermissions) {
    <p-tabs
      [scrollable]="true"
      [value]="0"
      [style]="{
        height: '100%',
        display: 'grid',
        'grid-template-rows': 'min-content 1fr',
        gap: '16px',
      }"
    >
      <p-tablist>
        <p-tab [value]="0">{{ 'assetPermissions.user' | translate }}</p-tab>
        <p-tab [value]="1">{{ 'assetPermissions.group' | translate }}</p-tab>
      </p-tablist>
      <p-tabpanels>
        <p-tabpanel [value]="0">
          <app-permissions [assetDetails]="asset" [narrow]="false"></app-permissions>
        </p-tabpanel>
        <p-tabpanel [value]="1">
          <app-group-permissions [assetDetails]="asset" [narrow]="false"></app-group-permissions>
        </p-tabpanel>
      </p-tabpanels>
    </p-tabs>
  } @else {
    <app-permissions [assetDetails]="asset" [narrow]="false"></app-permissions>
  }
} @else {
  <div class="insights">
    <div [innerHTML]="'permission-insights.own-permission.' + asset.permissions | translate"></div>
    <app-permission-insights [uuid]="asset.uuid"></app-permission-insights>
  </div>
}
<div>
  <button
    pButton
    type="button"
    label="{{ 'buttons.close' | translate }}"
    (click)="close()"
  ></button>
</div>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TrashListItem, assetTypeMappingToString } from '@assethub/shared/models';
import {
  ConfirmationService,
  NoDestinationFoundError,
  TrashService,
  TreeService,
} from '@assethub/shared/services';
import { LazyLoadTable } from '@assethub/shared/utils';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService, SharedModule } from 'primeng/api';
import { finalize, switchMap } from 'rxjs';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';
import { LazyLoadTableMessagesComponent } from '../lazy-load-table-messages/lazy-load-table-messages.component';
import { ButtonModule } from 'primeng/button';
import { NavigationSpinnerComponent } from '../navigation-spinner/navigation-spinner.component';
import { TableBaseComponent, TableColumn } from '../table-base/table-base.component';
import { AssetChipComponent } from '../asset-chip/asset-chip.component';
import { HumanReadableBytesPipe } from '../../pipes';

@Component({
  selector: 'app-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss'],
  standalone: true,
  imports: [
    NavigationSpinnerComponent,
    SharedModule,
    ButtonModule,
    LazyLoadTableMessagesComponent,
    TableBaseComponent,
    AssetChipComponent,
    TranslateModule,
    LocalizedDatePipe,
    HumanReadableBytesPipe,
  ],
})
export class TrashComponent {
  trashList: LazyLoadTable<TrashListItem>;
  initialColumns: (keyof TrashListItem)[] = [
    'rootCustomName',
    'customName',
    'type',
    'deletedAt',
    'purgeAt',
    'affectedAssets',
  ];
  columns: TableColumn<keyof TrashListItem>[] = [
    {
      field: 'rootCustomName',
      label: 'trash.list.rootName',
      essential: true,
      type: 'string',
      filter: true,
    },
    {
      field: 'customName',
      label: 'trash.list.assetName',
      essential: true,
      type: 'string',
      filter: true,
    },
    {
      field: 'type',
      label: 'trash.list.assetType',
      type: 'number',
      filterLabels: 'assetTypes',
    },
    {
      field: 'deletedAt',
      label: 'trash.list.deletedAt',
      type: 'date',
      filter: true,
    },
    {
      field: 'purgeAt',
      label: 'trash.list.remainingDays',
      type: 'unsortable',
    },
    {
      field: 'affectedAssets',
      label: 'trash.list.assetCount',
      type: 'number',
    },
    {
      field: 'affectedFilesize',
      label: 'trash.list.assetFilesize',
      type: 'number',
    },
  ];

  private readonly ONE_DAY_MILLIS = 1000 * 60 * 60 * 24;

  constructor(
    private trashService: TrashService,
    private router: Router,
    private treeService: TreeService,
    private translateService: TranslateService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) {
    this.trashList = new LazyLoadTable<TrashListItem>(
      params => this.trashService.listTrashItems(params),
      'deletedAt',
      -1,
    );
  }

  doRestoreCheck(event: TrashListItem) {
    this.trashList.loading = true;
    this.trashService.trashRestoreCheck(event.uuid).subscribe({
      next: result => {
        if (result.duplicateProducts !== undefined) {
          this.trashList.loading = false;
          if (result.duplicateProducts.count === 1) {
            this.confirmationService.confirm({
              translationKey: 'trash.singleDuplicateDevice',
              accept: () => this.restoreFromTrash(event),
            });
            return;
          } else {
            this.confirmationService.confirm({
              translationKey: 'trash.multipleDuplicateDevices',
              accept: () => this.restoreFromTrash(event),
              placeholders: {
                conflictCount: result.duplicateProducts.count,
              },
            });
            return;
          }
        }
        this.restoreFromTrash(event);
      },
      error: ex => {
        this.trashList.loading = false;
        this.handleRestoreErrors(ex, event);
      },
    });
  }

  private restoreFromTrash(event: TrashListItem) {
    this.trashList.loading = true;
    this.trashService
      .restoreFromTrash(event.uuid)
      .pipe(
        switchMap(() => {
          if (event.rootUuid === undefined) {
            return this.treeService.loadTree(event.uuid);
          } else {
            return this.treeService.fetchBranch(event.uuid);
          }
        }),
        finalize(() => (this.trashList.loading = false)),
      )
      .subscribe({
        next: () => {
          this.router.navigate(['/', 'asset', event.uuid, 'asset-management']);
        },
        error: ex => this.handleRestoreErrors(ex, event),
      });
  }

  private handleRestoreErrors(ex: any, event: TrashListItem) {
    if (ex instanceof NoDestinationFoundError) {
      this.messageService.add({
        severity: 'warn',
        summary: 'toasts.trash.noDestination',
        data: {
          missingRoot:
            ex.missingRoot ||
            this.translateService.instant(
              event.rootType
                ? 'assetTypes.' + assetTypeMappingToString[event.rootType]
                : 'toasts.unknown',
            ),
        },
        closable: true,
        sticky: true,
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'toasts.trash.error',
        closable: true,
        sticky: true,
      });
      throw ex;
    }
  }

  getRemainingDays(purgeAt: Date): number {
    const now = new Date();
    const purgeDate = new Date(purgeAt);
    const diff = (purgeDate.getTime() - now.getTime()) / this.ONE_DAY_MILLIS;
    const remainingDays = Math.floor(diff);
    return remainingDays;
  }
}

import { LanguageService } from '@assethub/shared/services';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CalloutComponent } from '../../callout/callout.component';

@Component({
  selector: 'app-service-permission-modal',
  templateUrl: './service-permission-modal.component.html',
  styleUrls: ['./service-permission-modal.component.scss'],
  standalone: true,
  imports: [CalloutComponent, ButtonModule, TranslateModule],
})
export class ServicePermissionModalComponent implements OnInit {
  warningText: string = '';

  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private languageService: LanguageService,
  ) {
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
  }

  ngOnInit(): void {
    if (this.config.data.group) {
      this.warningText = this.languageService.instant('assetPermissions.serviceWarning.group');
    } else {
      this.warningText = this.languageService.instant('assetPermissions.serviceWarning.user');
    }
  }

  onGrantPermission() {
    this.ref.close(true);
  }

  onCancel() {
    this.ref.close(false);
  }
}

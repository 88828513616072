<div class="release-note-dialog">
  <div class="header">
    <span class="title">{{ 'releaseNotes.title' | translate }}</span>
    <div class="buttons">
      <button
        pButton
        type="button"
        class="ui-button-bare subtitle"
        icon="pi pi-small pi-chevron-right"
        iconPos="right"
        label="{{ 'releaseNotes.subtitle' | translate }}"
        (click)="navigateToReleaseNotes()"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-bare-neutral"
        icon="pi pi-times"
        (click)="close()"
      ></button>
    </div>
  </div>
  <div class="release-note">
    <span class="releaseNoteDate">{{ releaseNote.date | localizedDate: 'longDate' }}</span>
    <span class="releaseNoteTitle">{{ releaseNote.title }}</span>
    <app-release-note [releaseNote]="releaseNote"></app-release-note>
  </div>
  <div class="footer">
    <div class="labeled-element">
      <p-checkbox
        inputId="release-notes-do-not-show-again"
        [binary]="true"
        [(ngModel)]="doNotShowAgain"
      ></p-checkbox>
      <label for="release-notes-do-not-show-again">{{
        'releaseNotes.doNotShowAgain' | translate
      }}</label>
    </div>
  </div>
</div>

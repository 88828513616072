<div class="trash">
  <app-navigation-spinner></app-navigation-spinner>
  <h4 class="header">{{ 'trash.header.finalDelete' | translate }}</h4>
  <div class="scroll">
    <app-table-base
      #table
      [list]="trashList"
      [columns]="columns"
      [initialColumns]="initialColumns"
      tableName="trash"
      noDataMsg="trash.list.noRecordsFound"
      ><ng-template #cellTemplate let-column let-row="row">
        @switch (column.field) {
          @case ('rootCustomName') {
            {{ row.rootCustomName }}
          }
          @case ('customName') {
            {{ row.customName }}
          }
          @case ('type') {
            <app-asset-chip [asset]="{ type: row.type }"></app-asset-chip>
          }
          @case ('deletedAt') {
            {{ row.deletedAt | localizedDate: 'mediumDate' }}
          }
          @case ('purgeAt') {
            {{ getRemainingDays(row.purgeAt) }}
          }
          @case ('affectedAssets') {
            {{ row.affectedAssets }}
          }
          @case ('affectedFilesize') {
            {{ row.affectedFilesize | humanReadableBytes }}
          }
          @case ('actions') {
            <button
              pButton
              type="button"
              class="ui-button-bare-neutral"
              icon="pi pi-restore-from-trash"
              [title]="'trash.list.restore' | translate"
              (click)="doRestoreCheck(row)"
            ></button>
          }
        }</ng-template
    ></app-table-base>
  </div>
</div>

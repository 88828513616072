import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { UserConfigurationService } from '@assethub/shared/services';
import { AppSharedModule } from '@assethub/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslatedReleaseNote } from '../..';
import { ReleaseNoteComponent } from '../release-note/release-note.component';

@Component({
  selector: 'app-release-note-dialog',
  templateUrl: './release-note-dialog.component.html',
  styleUrls: ['./release-note-dialog.component.scss'],
  standalone: true,
  imports: [
    ButtonModule,
    ReleaseNoteComponent,
    CheckboxModule,
    FormsModule,
    TranslateModule,
    AppSharedModule,
  ],
})
export class ReleaseNoteDialogComponent implements OnInit {
  public releaseNote: TranslatedReleaseNote;
  public doNotShowAgain = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private router: Router,
    private userConfig: UserConfigurationService,
  ) {
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
  }

  ngOnInit() {
    this.releaseNote = this.config.data.releaseNote;
    this.ref.onClose.subscribe({
      next: () => {
        if (this.doNotShowAgain) {
          this.userConfig.setLastReadReleaseNote(this.releaseNote.date);
        }
      },
    });
  }

  close() {
    this.ref.close();
  }

  navigateToReleaseNotes() {
    this.router.navigate(['newsfeed']);
    this.ref.close();
  }
}

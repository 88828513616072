<div>
  <p-select
    #treeSelector
    [scrollHeight]="maxHeight"
    [overlayOptions]="{
      showTransitionOptions: '0ms',
      hideTransitionOptions: '0ms',
    }"
    [options]="treeModel"
    [(ngModel)]="activeTree"
    (onChange)="selectTree($event)"
    dataKey="uuid"
  >
    <ng-template let-tree pTemplate="selectedItem">
      <app-asset-icon [asset]="tree.value"></app-asset-icon>
      <p>{{ tree.value | assetTitle }}</p>
      <app-icon *ngIf="tree.value.crm365" size="22" shape="build"></app-icon>
    </ng-template>
    <ng-template let-tree pTemplate="item">
      <ng-container *ngIf="!disableNonServiceTrees || tree.value.crm365">
        <app-asset-icon [asset]="tree.value"></app-asset-icon>
        <p>{{ tree.value | assetTitle }}</p>
        <app-icon *ngIf="tree.value.crm365" size="22" shape="build"></app-icon>
      </ng-container>
      <ng-container *ngIf="!tree.value.crm365 && disableNonServiceTrees" class="disableClicks">
        <app-asset-icon [disabled]="true" [asset]="tree.value"></app-asset-icon>
        <p class="disabled">{{ tree.value | assetTitle }}</p>
      </ng-container>
    </ng-template>
  </p-select>
</div>

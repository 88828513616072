import { Component } from '@angular/core';
import { MessageService, ToastMessageOptions } from 'primeng/api';
import { TranslateModule } from '@ngx-translate/core';
import { CalloutComponent } from '../callout/callout.component';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [ToastModule, CalloutComponent, TranslateModule],
})
export class ToastComponent {
  constructor(private messageService: MessageService) {}

  public onClose(message: ToastMessageOptions) {
    this.messageService.clear(message.id);
  }
}

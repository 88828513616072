import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackComponent } from '@assethub/shared/components/feedback/feedback.component';
import { ENVIRONMENT } from '@assethub/shared/models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { InternationalizationService } from '../../services/internationalization.service';
import { GlobalSearchComponent } from '../global-search/global-search.component';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { MenuComponent } from '../menu/menu/menu.component';

@UntilDestroy()
@Component({
  selector: 'app-sick-header',
  templateUrl: './sick-header.component.html',
  styleUrls: ['./sick-header.component.scss'],
  standalone: true,
  imports: [GlobalSearchComponent, ButtonModule, MenuComponent, MenuItemComponent, TranslateModule],
})
export class SickHeaderComponent {
  public environment = inject(ENVIRONMENT);
  public showFeedbackButton = this.environment.envSpecificFeatures.feedbackButton;
  public showGuiCheckPage = this.environment.envSpecificFeatures.guiCheckPage;
  public showAppsMenuInHeader = this.environment.envSpecificFeatures.showAppsMenu;
  public searchExpanded: boolean = false;
  public copyrightYear = new Date().getFullYear();

  public readonly languages = [
    { code: 'de', label: 'Deutsch' },
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'Français', disabled: true },
    { code: 'jp', label: '日本語', disabled: true },
    { code: 'ru', label: 'Русский', disabled: true },
    { code: 'ch', label: '中文', disabled: true },
  ];

  public readonly apps = [
    {
      label: 'Webshop',
      url: 'https://www.sick.com',
      icon: 'assets/images/sick-logo-small.svg',
    },
    {
      label: 'Digital Service Catalog',
      url: 'https://cloud.sick.com',
      icon: 'assets/images/sick-logo-small.svg',
    },
    {
      label: 'Support Portal',
      url: 'https://support.sick.com',
      icon: 'assets/images/apps/SupportPortal.svg',
    },
    {
      label: 'AppPool',
      url: 'https://apppool.cloud.sick.com',
      icon: 'assets/images/apps/AppPool.png',
    },
    {
      label: 'dStudio',
      url: 'https://dstudio.cloud.sick.com',
      icon: 'assets/images/apps/dStudio.png',
    },
    {
      label: 'Field Analytics',
      url: 'https://fa.analytics.cloud.sick.com',
      icon: 'assets/images/apps/FieldAnalytics.jpg',
    },
    {
      label: 'Package Analytics',
      url: 'https://package.analytics.cloud.sick.com',
      icon: 'assets/images/apps/PackageAnalytics.png',
    },
  ];

  public readonly legalLinks = [
    {
      label: this.translateService.instant('footer.imprint'),
      url: 'https://www.sick.com/imprint',
    },
    {
      label: this.translateService.instant('footer.terms-and-conditions'),
      url: 'https://www.sick.com/tac',
    },
    {
      label: this.translateService.instant('footer.terms-of-use'),
      url: 'https://www.sick.com/terms-of-use',
    },
    {
      label: this.translateService.instant('footer.data-protection'),
      url: 'https://www.sick.com/dataprotection',
    },
    {
      label: '© ' + this.copyrightYear + ' SICK AG',
      disabled: true,
    },
  ];

  @ViewChild('appMenu')
  appMenu: ElementRef;

  @ViewChild('userMenu')
  userMenu: ElementRef;

  language: string;
  flyoutMenuVisible = false;

  private oidcProfile = this.environment.oidcProfile;
  private oidcPassChange = this.environment.oidcPassChange;

  constructor(
    private router: Router,
    private auth: OAuthService,
    private internationalizationService: InternationalizationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {
    this.language = this.translateService.currentLang;
  }

  public hideFlyouts() {
    this.appMenu.nativeElement.open = false;
    this.userMenu.nativeElement.open = false;
    this.flyoutMenuVisible = false;
  }

  onShowFeedbackModal() {
    this.dialogService.open(FeedbackComponent, {
      header: this.translateService.instant('feedback.title'),
    });
  }

  onShowProfile() {
    window.open(this.oidcProfile, '_blank');
  }

  onShowLicenses() {
    window.open('assets/licenses.txt', '_blank');
  }

  onChangePassword() {
    window.open(this.oidcPassChange, '_blank');
  }

  onLogout() {
    this.auth.logOut();
  }

  onLanguageChange(code: string) {
    if (this.internationalizationService.setLang(code)) {
      // Trigger forced page reload to re-initialize state of whole app. Components might have cached translations,
      // so to refresh them a full page reload is necessary.
      window.location.reload();
    }
  }

  onOpenInNewWindow(url?: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }

  onGuiCheck() {
    this.router.navigate(['gui-check']);
  }

  onReleaseNotes() {
    this.router.navigate(['newsfeed']);
  }

  onTrashBin() {
    this.router.navigateByUrl('trash-bin');
  }
}

import { DOCUMENT, NgClass, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssetService } from '../../../services/asset.service';
import { CloneAssetOpt, CloneCheckResult, TreeService } from '../../../services/tree.service';
import { AssetItem, GetAssetResponse } from '../../../models';
import { AssetSelectionService } from '../../../services';
import { finalize } from 'rxjs';
import { Logger } from '@assethub/shared/utils';
import { ButtonModule } from 'primeng/button';
import { CalloutComponent } from '../../callout/callout.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-clone-asset-modal',
  templateUrl: './clone-asset-modal.component.html',
  styleUrls: ['./clone-asset-modal.component.scss'],
  standalone: true,
  imports: [
    CheckboxModule,
    FormsModule,
    NgIf,
    ProgressBarModule,
    CalloutComponent,
    ButtonModule,
    TranslateModule,
    NgClass,
  ],
})
export class CloneAssetModalComponent implements OnInit {
  recursive = true;
  includeCustomUploads = true;
  includePermissions = true;
  loading = false;

  cloneCheckResult: CloneCheckResult = {};
  private assetUuid: string;
  private logger = new Logger(this.constructor.name);

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private treeService: TreeService,
    private assetService: AssetService,
    private messageService: MessageService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private assetSelectionService: AssetSelectionService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  ngOnInit() {
    const asset: GetAssetResponse | AssetItem = this.config.data.asset;
    this.assetUuid = asset.uuid;
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
    this.assetSelectionService.markNode(this.assetUuid);
    this.checkForCloneIssues();
  }

  onCancel() {
    this.ref.close();
    this.assetSelectionService.markNode();
  }

  onOk() {
    this.loading = true;
    this.cloneCheckResult = {};
    this.config.closeOnEscape = false;
    this.treeService
      .cloneAsset(this.assetUuid, this.getCloneOpts())
      .pipe(
        finalize(() => {
          this.loading = false;
          this.ref.close();
          this.assetSelectionService.markNode();
        }),
      )
      .subscribe({
        next: clone => {
          this.messageService.add({
            key: 'assetClonedToast',
            severity: 'success',
            summary: 'toasts.asset.cloned',
            life: 10000,
            data: {
              asset_name: clone.name,
              asset_uuid: clone.uuid,
              origin: this.document.location.origin,
            },
          });
        },
        error: () => {
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.asset.clone-failed',
            life: 10000,
          });
        },
      });
  }

  checkForCloneIssues() {
    const asset: GetAssetResponse | AssetItem = this.config.data.asset;
    this.loading = true;
    this.treeService
      .cloneCheck(asset.uuid, this.getCloneOpts())
      .pipe(finalize(() => (this.loading = false)))
      .subscribe({
        next: checkResult => {
          this.cloneCheckResult = checkResult;
        },
        error: err => {
          this.logger.error('Error during clone preflight check: ', err);
        },
      });
  }

  private getCloneOpts(): CloneAssetOpt {
    return {
      recursive: this.recursive,
      includeCustomUploads: this.includeCustomUploads,
      includePermissions: this.includePermissions,
      assetTypeName: this.assetService.getTranslatedName().assetTypeName,
    };
  }
}

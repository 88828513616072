import { Component, ViewChild, computed, input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetRootItem } from '@assethub/shared/models';
import { AssetTitlePipe } from '@assethub/shared/pipes';
import {
  DraggedAssetNode,
  MapEditModes,
  MapEditService,
  TreeDragDropService,
  TreeService,
  UserConfigurationService,
} from '@assethub/shared/services';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { TreeSelectorComponent } from '../tree-selector/tree-selector.component';
import { SwitchTreeComponent } from './../modals/switch-tree/switch-tree.component';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';
import { AssetDropZoneComponent } from '../asset-drop-zone/asset-drop-zone.component';
import { pathFromRoutingTemplate } from '@assethub/shared/utils';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, concat } from 'rxjs';

@Component({
  selector: 'app-tree-headline',
  templateUrl: './tree-headline.component.html',
  styleUrls: ['./tree-headline.component.scss'],
  standalone: true,
  imports: [AssetDropZoneComponent, TreeSelectorComponent, BreadcrumbComponent],
})
export class TreeHeadlineComponent {
  readonly assetId = input<string>();
  readonly onlyServiceTrees = input<boolean>(false);
  readonly customBreadcrumbs = input<MenuItem[]>();

  readonly treeId = computed(() => this.computeTreeId());
  readonly breadcrumbs = computed(() => this.computeBreadcrumbs());
  readonly trees = toSignal(this.trackTrees(), { initialValue: [] });
  readonly draggedNode = toSignal(this.trackDragNDropOfNodes());

  private readonly config = toSignal(this.userConfigService.config());
  private readonly editMode = toSignal(this.mapEditService.changeEditMode$);
  private lastNodeChange = 0;
  private readonly nodeChanged = toSignal(
    this.treeService.nodeChanged.pipe(map(x => ({ uuid: x, ts: Date.now() }))),
  );

  @ViewChild('treeSelector')
  private treeSelector?: TreeSelectorComponent;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private treeService: TreeService,
    private userConfigService: UserConfigurationService,
    private assetTitlePipe: AssetTitlePipe,
    private mapEditService: MapEditService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private treeDragDropService: TreeDragDropService,
  ) {}

  onAssetDropped(asset: DraggedAssetNode) {
    this.dialogService.open(SwitchTreeComponent, {
      header: this.translateService.instant('switchAssetTree.dialog.title'),
      data: {
        assetId: asset.uuid,
      },
      dismissableMask: true,
      closable: true,
      modal: true,
      contentStyle: { flex: '1', display: 'flex' },
      style: {
        'min-height': '372px',
        'max-height': '600px',
        'min-width': '700px',
      },
    });
  }

  navigateToTree(treeRootId: string) {
    const tree = this.treeService.getTreeByUuid(treeRootId);

    if (!tree) {
      throw new Error(`No tree with uuid = ${treeRootId} was found`);
    }

    // Go to most recently visited node of this tree (which defaults to root node)
    return this.router.navigate(
      pathFromRoutingTemplate(this.route.snapshot, {
        uuid: tree.selectedNode,
        rootUuid: treeRootId,
      }),
    );
  }

  navigateToAsset(uuid: string) {
    return this.router.navigate(pathFromRoutingTemplate(this.route.snapshot, { uuid }));
  }

  private computeBreadcrumbs(): MenuItem[] {
    const customBreadcrumbs = this.customBreadcrumbs();
    if (customBreadcrumbs) {
      return customBreadcrumbs;
    }
    const assetId = this.assetId();
    if (!assetId) {
      return [];
    }
    const nodeChanged = this.nodeChanged();
    const treeId = this.treeId();
    // Signals repeat the last value they have seen. Before processing make sure the event is "fresh".
    if (nodeChanged && this.lastNodeChange < nodeChanged.ts) {
      this.lastNodeChange = nodeChanged.ts;
      if (treeId === nodeChanged.uuid) {
        // Update the selected value field of the tree selector if we changed properties of our active root
        this.treeSelector?.updateUi();
      }
    }
    const config = this.config();
    const editMode = this.editMode();
    const breadcrumbs = this.treeService.buildBreadcrumb(assetId).map<MenuItem>(b => ({
      label: this.assetTitlePipe.transform(b, config?.showAsTitle),
      id: b.uuid,
    }));
    if (breadcrumbs.length > 0) {
      if (editMode === MapEditModes.EDIT_PIN) {
        breadcrumbs.at(-1)!.disabled = true;
        breadcrumbs.push({
          label: this.translateService.instant('mapView.menu.edit'),
          id: 'edit_pin',
        });
      }
      if (editMode === MapEditModes.EDIT_MAP) {
        breadcrumbs.at(-1)!.disabled = true;
        breadcrumbs.push({
          label: this.translateService.instant('mapView.menu.editMap'),
          id: 'edit_map',
        });
      }
    }
    return breadcrumbs;
  }

  private computeTreeId(): string | undefined {
    const assetId = this.assetId();
    if (!assetId) {
      return;
    }
    const node = this.treeService.findNode(assetId);
    if (!node) {
      return;
    }
    return node.root.uuid;
  }

  private trackTrees(): Observable<AssetRootItem[]> {
    return concat(
      this.treeService.fetchTrees(),
      this.treeService.treeChanged.pipe(map(x => this.treeService.rootNodes)),
    );
  }

  private trackDragNDropOfNodes(): Observable<DraggedAssetNode | undefined> {
    return this.treeDragDropService.$dndEvent.pipe(
      map(draggedNode => {
        if (draggedNode) {
          const rootNode = this.treeService.getTreeByUuid(draggedNode.rootUuid);
          if (rootNode === undefined) {
            return;
          }
          if (draggedNode.readonly) {
            // Avoid moving readonly nodes to another tree
            return;
          }
          const node = this.treeService.findNode(draggedNode.uuid);
          if (node?.parent?.virtual) {
            // Immediate children of the virtual root node cannot be moved away because they are shared sub-trees.
            return;
          }
          if (rootNode.owner !== undefined && rootNode.owner.uuid === undefined) {
            // At the moment users are not allowed to move nodes away from JointProduct-Style trees
            return;
          }
        }
        return draggedNode;
      }),
    );
  }
}

@if ((permissionList().length === 0 && loading()) || saving()) {
  <div class="progressbar">
    <p-progressBar mode="indeterminate"> </p-progressBar>
  </div>
} @else {
  <div class="placeholder"></div>
}

<div class="table-wrapper">
  <p-table
    [value]="permissionList()"
    [ngClass]="{ narrow: narrow() }"
    breakpoint="0px"
    [lazy]="true"
    [lazyLoadOnInit]="false"
    [paginator]="true"
    [alwaysShowPaginator]="false"
    [totalRecords]="totalRecords()"
    [rows]="pageSize()"
    [loading]="loading() || saving()"
    paginatorPosition="bottom"
    (onLazyLoad)="onLazyLoad($event)"
    [(first)]="paginatorStart"
    [scrollable]="true"
    styleClass="limit-height"
  >
    <ng-template pTemplate="header">
      <tr>
        @if (isGroupPermission()) {
          <th style="width: auto">
            {{ 'assetPermissions.table.group' | translate }}
          </th>
        } @else {
          <th style="width: auto">
            {{ 'assetPermissions.table.user' | translate }}
          </th>
        }
        <th>{{ 'assetPermissions.table.permission' | translate }}</th>
        <th style="width: 3em"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-row>
      <tr>
        <td>
          @if (isGroupPermission()) {
            <span class="p-column-title">{{ 'assetPermissions.group' | translate }}</span>
            @if (groupManagementUrl) {
              <a class="group-link" target="_blank" href="{{ groupManagementUrl }}{{ row.id }}"
                >{{ row.label }}
                <app-icon shape="pi pi-external-link" size="small"></app-icon>
              </a>
            }
          } @else {
            <span class="p-column-title">{{ 'assetPermissions.user' | translate }}</span>
            <label>{{ row.label }}</label>
          }
        </td>
        <td>
          <p-select
            appendTo="body"
            [options]="row.options"
            [checkmark]="true"
            [(ngModel)]="row.permission"
            (onChange)="onPermissionChanged(row)"
          >
          </p-select>
        </td>
        <td>
          <span class="p-column-title">{{ 'assetPermissions.delete' | translate }}</span>
          <div>
            <button
              pButton
              type="button"
              class="ui-button-bare"
              icon="pi pi-trash"
              (click)="onDeletePermission(row)"
              [disabled]="row.unerasable"
            ></button>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="3" class="empty-table-message">
          <div>
            {{
              (isGroupPermission()
                ? 'assetPermissions.noGroupPermissions'
                : 'assetPermissions.noPermissions'
              ) | translate
            }}
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

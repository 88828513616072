<div>
  <div class="flex flex-column mb-2">
    <p class="font-semibold">{{ 'export.export-scope-message' | translate }}:</p>
    <div class="flex flex-column gap-2 ml-2">
      @if (!isTreeRoot()) {
        <div class="labeled-element">
          <p-radioButton
            inputId="entire-tree"
            value="ENTIRE_TREE"
            [(ngModel)]="exportScope"
            [disabled]="loading"
          ></p-radioButton>
          <label for="entire-tree" [ngClass]="{ disabled: loading }">
            {{ 'export.entire-tree' | translate }}
          </label>
        </div>
      }
      <div class="labeled-element">
        <p-radioButton
          inputId="complete-subtree"
          value="SUBTREE"
          [(ngModel)]="exportScope"
          [disabled]="loading"
        ></p-radioButton>
        <label for="complete-subtree" [ngClass]="{ disabled: loading }">
          {{ 'export.complete-subtree' | translate }}
        </label>
      </div>
      <div class="labeled-element">
        <p-radioButton
          inputId="single-node"
          value="SINGLE_NODE"
          [(ngModel)]="exportScope"
          [disabled]="loading"
        ></p-radioButton>
        <label for="single-node" [ngClass]="{ disabled: loading }">
          {{ 'export.single-node' | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="flex flex-column mb-2">
    <p class="font-semibold">{{ 'export.includes.additional-asset-message' | translate }}:</p>
    <div class="flex flex-column gap-2 ml-2">
      <div class="labeled-element">
        <p-checkbox
          class="m-0"
          inputId="export-latest-sopas"
          [binary]="true"
          [(ngModel)]="additionalExportItems.latestSopasConfigurations"
          [disabled]="loading"
        ></p-checkbox>
        <label for="export-latest-sopas" [ngClass]="{ disabled: loading }">{{
          'export.includes.latest-sopas-configurations' | translate
        }}</label>
      </div>
    </div>
  </div>

  <div class="flex flex-column mt-4">
    <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
    <div class="flex justify-content-between gap-8">
      <button
        pButton
        type="button"
        class="ui-button-outline"
        [disabled]="loading"
        (click)="onCancel()"
        [label]="'buttons.cancel' | translate"
      ></button>
      <button
        pButton
        type="button"
        class="ui-button-primary"
        [disabled]="loading"
        (click)="startExport()"
        [label]="'export.download' | translate"
      ></button>
    </div>
  </div>
</div>

<span class="p-float-label always-expanded">
  <p-datepicker
    [name]="name"
    [(ngModel)]="contents"
    (blur)="onBlur()"
    [showIcon]="true"
    [showOnFocus]="false"
    showTransitionOptions="0s"
    hideTransitionOptions="0s"
    [placeholder]="placeholder"
    panelStyleClass="sds"
    [disabled]="disabled"
  ></p-datepicker>
  <label class="{{ disabled ? 'label-disabled' : '' }}">{{ label }}</label>
</span>

import { NgIf } from '@angular/common';
import { Component, inject } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ENVIRONMENT } from '@assethub/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { finalize, Observable, Subscription } from 'rxjs';
import { LabelPickerComponent } from '../../../label-picker/label-picker.component';
import { TooltipComponent } from '../../../tooltip/tooltip.component';

export interface BackupOptions {
  backupApps: boolean;
  backupParameters: boolean;
  backupPublicFolder: boolean;
  backupCustomName: string;
  labels: string[];
}

@Component({
  selector: 'app-create-device-backup-dialog',
  templateUrl: './create-device-backup.component.html',
  styleUrls: ['./create-device-backup.component.scss'],
  standalone: true,
  imports: [
    LabelPickerComponent,
    FormsModule,
    ReactiveFormsModule,
    CheckboxModule,
    NgIf,
    TooltipComponent,
    InputTextModule,
    ButtonModule,
    TranslateModule,
  ],
})
export class CreateDeviceBackupComponent {
  allowNewLabels = inject(ENVIRONMENT).envSpecificFeatures.allowNewConfigLabels;
  public backupSelectionForm: FormGroup = new FormGroup({
    backupAll: new FormControl<boolean>(true),
    backupCustomSelection: new FormGroup(
      {
        backupApps: new FormControl<boolean>(true),
        backupParameters: new FormControl<boolean>(true),
        backupPublicFolder: new FormControl<boolean>(true),
      },
      (groupControl: AbstractControl) =>
        groupControl.get('backupApps')?.value ||
        groupControl.get('backupParameters')?.value ||
        groupControl.get('backupPublicFolder')?.value
          ? null
          : { groupControl: false },
    ),
    backupCustomName: new FormControl<string>('', (control: AbstractControl) => {
      if (control.value.length > 255) {
        this.backupNameInputError =
          'assetConfigurations.modals.device-backup-create.error-backup-name-too-long';
        return { backupCustomName: false };
      }

      this.backupNameInputError = undefined;
      return null;
    }),
    labels: new FormControl<string[]>([]),
  });
  labelCallback?: (query: string) => Observable<string[]>;
  suggestions: string[] = [];

  public collapsed = true;
  public backupNameInputError: string | undefined;

  private labelSubscription?: Subscription;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) {
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
    this.labelCallback = this.config.data?.labelCallback;

    const checkAll = () => {
      this.backupSelectionForm
        .get('backupAll')
        ?.setValue(
          this.backupSelectionForm.get('backupCustomSelection.backupApps')?.value &&
            this.backupSelectionForm.get('backupCustomSelection.backupParameters')?.value &&
            this.backupSelectionForm.get('backupCustomSelection.backupPublicFolder')?.value,
          { emitEvent: false },
        );
    };

    this.backupSelectionForm.get('backupAll')?.valueChanges.subscribe({
      next: checked => {
        this.backupSelectionForm.get('backupCustomSelection.backupApps')?.setValue(checked);
        this.backupSelectionForm.get('backupCustomSelection.backupParameters')?.setValue(checked);
        this.backupSelectionForm.get('backupCustomSelection.backupPublicFolder')?.setValue(checked);
      },
    });

    this.backupSelectionForm.get('backupCustomSelection.backupApps')?.valueChanges.subscribe({
      next: backupApps => {
        const backupParametersControl = this.backupSelectionForm.get(
          'backupCustomSelection.backupParameters',
        );

        if (!backupApps) {
          backupParametersControl?.setValue(false);
          backupParametersControl?.disable();
        } else {
          backupParametersControl?.enable();
        }

        checkAll();
      },
    });

    this.backupSelectionForm.get('backupCustomSelection.backupParameters')?.valueChanges.subscribe({
      next: () => {
        checkAll();
      },
    });

    this.backupSelectionForm
      .get('backupCustomSelection.backupPublicFolder')
      ?.valueChanges.subscribe({
        next: () => {
          checkAll();
        },
      });
  }

  public close() {
    this.ref.close();
  }

  public createBackup() {
    const result: BackupOptions = {
      backupApps: this.backupSelectionForm.get('backupCustomSelection.backupApps')?.value,
      backupParameters: this.backupSelectionForm.get('backupCustomSelection.backupParameters')
        ?.value,
      backupPublicFolder: this.backupSelectionForm.get('backupCustomSelection.backupPublicFolder')
        ?.value,
      backupCustomName: this.backupSelectionForm.get('backupCustomName')?.value,
      labels: this.backupSelectionForm.get('labels')!.value,
    };
    this.ref.close(result);
  }

  changeCollapseState() {
    this.collapsed = !this.collapsed;
  }

  suggestLabels(query: string) {
    if (this.labelCallback) {
      if (this.labelSubscription) {
        this.labelSubscription.unsubscribe();
      }
      this.labelSubscription = this.labelCallback(query)
        .pipe(
          finalize(() => {
            this.labelSubscription = undefined;
          }),
        )
        .subscribe({
          next: suggestions => (this.suggestions = suggestions),
          error: () => (this.suggestions = []),
        });
    }
  }
}

import { Component } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PermissionInsightsComponent } from '../../asset-permissions/permission-insights/permission-insights.component';
import { AppInjector } from '../../../utils/app-injector';

interface DialogData {
  uuid: string;
  messageId: string;
}

@Component({
  selector: 'app-administrators-modal',
  templateUrl: './administrators-modal.component.html',
  styleUrls: ['./administrators-modal.component.scss'],
  standalone: true,
  imports: [ButtonModule, TranslateModule, PermissionInsightsComponent],
})
export class AdministratorsModalComponent {
  readonly uuid: string;
  readonly messageId: string;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<DialogData>,
    translateService: TranslateService,
  ) {
    this.config.header = translateService.instant('permission-insights.title');
    this.config.focusOnShow = false;
    this.config.style = { 'max-width': '50em', 'min-height': '80%', 'max-height': '80%' };
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
    const data = this.config.data;
    if (data) {
      this.uuid = data.uuid;
      this.messageId = data.messageId;
    }
  }

  static open(uuid: string, messageId: string) {
    const data: DialogData = { uuid, messageId };
    return AppInjector.get(DialogService).open(AdministratorsModalComponent, { data });
  }

  close() {
    this.ref.close();
  }
}

import { HistoryEventFormatterPipe } from './pipes/history-event-formatter.pipe';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSplitModule } from 'angular-split';
import {
  BreadcrumbComponent,
  AuthNotificationComponent,
  CardComponent,
  ErrorComponent,
  FeedbackComponent,
  MenuComponent,
  AssetErrorComponent,
  NoElementsPlaceholderComponent,
  SickHeaderComponent,
  TextFieldComponent,
  SnapshotComponent,
  ToastComponent,
  TooltipComponent,
  DateRangePickerComponent,
  AssetIconComponent,
  LiveConnectAssetAddedComponent,
  ProgressStatusComponent,
  TreeSelectorComponent,
  TreeHeadlineComponent,
  SplitViewComponent,
  DividerComponent,
  AcceptCancelOptionsComponent,
  AssetDetailHeaderComponent,
  AssetOptionMenuComponent,
  NavigationSpinnerComponent,
  TagComponent,
  MenuItemComponent,
  BasicHeremapComponent,
  FileChooserComponent,
  AssetIconLabelComponent,
  SubmenuComponent,
  BouncingPinComponent,
  AssetHeremapComponent,
  LazyLoadTableMessagesComponent,
  NumberFieldComponent,
  GroupPermissionsAddComponent,
  LinksEditComponent,
  AssetLocationLabelComponent,
  AssetLocationEditComponent,
  AssetLocationMapComponent,
  ShipLocationMapComponent,
} from './components';
import { modalComponents } from './components/modals';
import { menuItemComponents } from './components/menu-items';
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import {
  CompleteUrlPipe,
  AssetIconPipe,
  AssetTitlePipe,
  HumanReadableBytesPipe,
  HighlightTextPipe,
  LocalizedDatePipe,
  SafeHtmlPipe,
  LocalizedCurrencyPipe,
} from './pipes';
import { AssetImageUrlPipe } from './pipes/asset-image-url.pipe';
import { PrimengModule } from './primeng.module';
import { SwitchTreeComponent } from './components/modals/switch-tree/switch-tree.component';
import { AssetDropZoneComponent } from './components/asset-drop-zone/asset-drop-zone.component';
import { ExpandableSearchFieldComponent } from './components/expandable-search-field/expandable-search-field.component';
import { PermissionsViewComponent } from './components/permissions-view/permissions-view.component';
import { PermissionsAddComponent } from './components/permissions-add/permissions-add.component';
import { ColorBarComponent } from './components/color-bar/color-bar.component';
import { IconComponent } from './components/icon/icon.component';
import { SortIconComponent } from './components/sort-icon/sort-icon.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableOptionsMenuComponent } from './components/table-options-menu/table-options-menu.component';
import { TableBaseComponent } from './components/table-base/table-base.component';
import { CalloutComponent } from './components/callout/callout.component';
import { SliderComponent } from './components';
import { OutsideActionDirective } from './directives/action-outside.directive';
import { DrawerNavigationComponent } from './components/drawer-navigation/drawer-navigation.component';
import { DrawerNavigationItemComponent } from './components/drawer-navigation-item/drawer-navigation-item.component';
import { DeviceChildrenComponent } from './components/device-children/device-children.component';
import { DeviceService } from './services/device.service';
import { FirmwareStateIconComponent } from './components/firmware-state-icon/firmware-state-icon.component';
import { DateFieldComponent } from './components/date-field/date-field.component';
import { TranslateWithFallbackPipe } from './pipes/translate-with-fallback.pipe';
import { ResizedDirective } from './directives/resized';
import { AssetClonedToastComponent } from './components/asset-cloned-toast/asset-cloned-toast.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { sm365Components } from './components/sm365';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { TrashComponent } from './components/trash-bin/trash.component';
import { SensorAppsComponent } from './components/sensor-apps/sensor-apps.component';
import { AssetChipComponent } from './components/asset-chip/asset-chip.component';
import { LabelPickerComponent } from './components/label-picker/label-picker.component';
import { SpinnerButtonComponent } from './components/spinner-button/spinner-button.component';
import { InputSwitchComponent } from './components/input-switch/input-switch.component';
import { TexAreaComponent } from './components/text-area/text-area.component';
import { setAppInjector } from './utils/app-injector';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    AngularSplitModule,
    PrimengModule,
    BreadcrumbComponent,
    AuthNotificationComponent,
    CardComponent,
    CompleteUrlPipe,
    AssetIconPipe,
    AssetTitlePipe,
    LocalizedCurrencyPipe,
    ErrorComponent,
    FeedbackComponent,
    MenuComponent,
    AssetErrorComponent,
    NoElementsPlaceholderComponent,
    SickHeaderComponent,
    TextFieldComponent,
    HumanReadableBytesPipe,
    SnapshotComponent,
    HighlightTextPipe,
    ToastComponent,
    TooltipComponent,
    DateRangePickerComponent,
    AssetIconComponent,
    LiveConnectAssetAddedComponent,
    ProgressStatusComponent,
    TreeSelectorComponent,
    TreeHeadlineComponent,
    SplitViewComponent,
    DividerComponent,
    AcceptCancelOptionsComponent,
    AssetDetailHeaderComponent,
    AssetOptionMenuComponent,
    NavigationSpinnerComponent,
    TagComponent,
    AssetImageUrlPipe,
    MultiselectComponent,
    LocalizedDatePipe,
    MenuItemComponent,
    HistoryEventFormatterPipe,
    SwitchTreeComponent,
    AssetDropZoneComponent,
    ExpandableSearchFieldComponent,
    PermissionsViewComponent,
    PermissionsAddComponent,
    ColorBarComponent,
    BasicHeremapComponent,
    AssetHeremapComponent,
    IconComponent,
    SortIconComponent,
    TableHeaderComponent,
    TableOptionsMenuComponent,
    TableBaseComponent,
    FileChooserComponent,
    CalloutComponent,
    SafeHtmlPipe,
    SliderComponent,
    OutsideActionDirective,
    DrawerNavigationComponent,
    DrawerNavigationItemComponent,
    AssetIconLabelComponent,
    DeviceChildrenComponent,
    FirmwareStateIconComponent,
    DateFieldComponent,
    SubmenuComponent,
    TranslateWithFallbackPipe,
    BouncingPinComponent,
    ResizedDirective,
    AssetClonedToastComponent,
    ConfirmationModalComponent,
    LazyLoadTableMessagesComponent,
    NumberFieldComponent,
    GroupPermissionsAddComponent,
    GlobalSearchComponent,
    TrashComponent,
    ...modalComponents,
    ...menuItemComponents,
    ...sm365Components,
    SensorAppsComponent,
    AssetChipComponent,
    LabelPickerComponent,
    SpinnerButtonComponent,
    InputSwitchComponent,
    TexAreaComponent,
    LinksEditComponent,
    AssetLocationLabelComponent,
    AssetLocationEditComponent,
    AssetLocationMapComponent,
    ShipLocationMapComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    AngularSplitModule,
    BreadcrumbComponent,
    AuthNotificationComponent,
    CardComponent,
    CommonModule,
    CompleteUrlPipe,
    AssetIconPipe,
    AssetTitlePipe,
    LocalizedCurrencyPipe,
    ErrorComponent,
    FeedbackComponent,
    FormsModule,
    MenuComponent,
    AssetErrorComponent,
    NoElementsPlaceholderComponent,
    PrimengModule,
    ReactiveFormsModule,
    SickHeaderComponent,
    HighlightTextPipe,
    TextFieldComponent,
    TranslateModule,
    HumanReadableBytesPipe,
    SnapshotComponent,
    ToastComponent,
    TooltipComponent,
    DateRangePickerComponent,
    AssetIconComponent,
    LiveConnectAssetAddedComponent,
    ProgressStatusComponent,
    TreeHeadlineComponent,
    SplitViewComponent,
    DividerComponent,
    AcceptCancelOptionsComponent,
    AssetDetailHeaderComponent,
    AssetOptionMenuComponent,
    NavigationSpinnerComponent,
    TagComponent,
    AssetImageUrlPipe,
    MultiselectComponent,
    LocalizedDatePipe,
    MenuItemComponent,
    TreeSelectorComponent,
    HistoryEventFormatterPipe,
    ExpandableSearchFieldComponent,
    PermissionsViewComponent,
    PermissionsAddComponent,
    ColorBarComponent,
    BasicHeremapComponent,
    AssetHeremapComponent,
    IconComponent,
    SortIconComponent,
    TableHeaderComponent,
    TableOptionsMenuComponent,
    TableBaseComponent,
    FileChooserComponent,
    CalloutComponent,
    SafeHtmlPipe,
    SliderComponent,
    OutsideActionDirective,
    DrawerNavigationComponent,
    AssetIconLabelComponent,
    DeviceChildrenComponent,
    FirmwareStateIconComponent,
    DateFieldComponent,
    SubmenuComponent,
    TranslateWithFallbackPipe,
    BouncingPinComponent,
    ResizedDirective,
    AssetClonedToastComponent,
    ConfirmationModalComponent,
    LazyLoadTableMessagesComponent,
    NumberFieldComponent,
    GroupPermissionsAddComponent,
    GlobalSearchComponent,
    TrashComponent,
    ...modalComponents,
    ...menuItemComponents,
    ...sm365Components,
    SensorAppsComponent,
    AssetChipComponent,
    LabelPickerComponent,
    SpinnerButtonComponent,
    InputSwitchComponent,
    TexAreaComponent,
    LinksEditComponent,
    AssetLocationLabelComponent,
    AssetLocationEditComponent,
    AssetLocationMapComponent,
    ShipLocationMapComponent,
  ],
  providers: [AssetImageUrlPipe, DeviceService, AssetIconPipe, CompleteUrlPipe],
})
export class AppSharedModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}

import { NgClass } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject, signal } from '@angular/core';
import { File } from '@assethub/shared/components/sm365/download-service-file/download-service-file.component';
import { ENVIRONMENT } from '@assethub/shared/models';
import { LazyLoadResponse, LazyLoadTable, fromUnixTimestamp } from '@assethub/shared/utils';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { NEVER, map } from 'rxjs';
import { LocalizedDatePipe } from '../../../pipes/localized-date.pipe';
import { TableBaseComponent, TableColumn } from '../../table-base/table-base.component';
import { DownloadServiceFileComponent } from '../download-service-file/download-service-file.component';

interface Document extends File {
  description: string;
  lastModified: Date;
  ellipsis: boolean;
}

interface DocumentsResponse {
  documents: {
    title: string;
    description?: string;
    type: string;
    uuid: string;
    lastModified: number;
  }[];
  total: number;
}

@Component({
  selector: 'app-list-work-order-documents',
  templateUrl: './list-work-order-documents.component.html',
  styleUrls: ['./list-work-order-documents.component.scss'],
  standalone: true,
  imports: [
    TableModule,
    NgClass,
    SharedModule,
    DownloadServiceFileComponent,
    TranslateModule,
    LocalizedDatePipe,
    ButtonModule,
    TableBaseComponent,
  ],
})
export class ListWorkOrderDocumentsComponent implements OnInit {
  nodeUuid = '';
  workOrderNumber = signal<number | undefined>(undefined);
  workOrderName = signal<string>('');
  workOrderCustomerReference = signal<string>('');

  mainList: LazyLoadTable<Document>;

  columns: TableColumn<keyof Document>[] = [
    {
      field: 'title',
      label: 'service-module-365.document.field.filename',
      type: 'unsortable',
      filter: true,
      essential: true,
    },
    {
      field: 'lastModified',
      label: 'service-module-365.document.field.last-modified',
      type: 'date',
      filter: true,
      width: '20%',
    },
  ];

  private sm365Url = inject(ENVIRONMENT).sm365Url;

  constructor(
    private httpClient: HttpClient,
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) {
    this.config.dismissableMask = true;
    this.config.modal = true;
    this.config.closable = true;
    this.nodeUuid = config.data.nodeUuid;
    this.workOrderNumber.set(config.data.workOrder.number);
    this.workOrderName.set(config.data.workOrder.name || '');
    this.workOrderCustomerReference.set(config.data.workOrder.customerReference || '');
    this.mainList = new LazyLoadTable<Document>(
      x => this.requestDocuments(x),
      'lastModified',
      -1,
      5,
    );
  }

  ngOnInit() {
    this.onLazyLoad({});
  }

  onLazyLoad(event: TableLazyLoadEvent) {
    this.mainList.onLazyLoad(event);
  }

  private requestDocuments(params: { [k: string]: string }): LazyLoadResponse<Document> {
    if (!this.nodeUuid || !this.workOrderNumber()) {
      return NEVER;
    }
    return this.httpClient
      .get<DocumentsResponse>(
        `${this.sm365Url}/tree/${this.nodeUuid}/workorder/${this.workOrderNumber()}/documents`,
        {
          params,
        },
      )
      .pipe(
        map(response => ({
          total: response.total,
          items: response.documents.map(x => ({
            title: x.title,
            description: x.description || '-',
            type: x.type,
            id: x.uuid,
            assetUuid: this.nodeUuid,
            lastModified: fromUnixTimestamp(x.lastModified),
            ellipsis: true,
            downloading: false,
          })),
        })),
      );
  }

  public toggleEllipsis(row: number) {
    const index = row >= this.mainList.visibleRows ? row - this.mainList.visibleRows : row;
    this.mainList.items[index].ellipsis = !this.mainList.items[index].ellipsis;
  }
}

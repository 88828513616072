<h4>{{ 'asset.location' | translate }}</h4>
<div (resized)="onResized($event)">
  <!-- text introduction block -->
  <p [innerHtml]="'geoLocation.introduction' | translate"></p>

  <div class="layout" [ngClass]="{ narrow: narrow() }">
    <!-- left panel: input fields -->
    <div class="left">
      <div class="radio-buttons">
        <div class="labeled-element">
          <p-radioButton
            inputId="use-inherited"
            [value]="true"
            [(ngModel)]="edit.useInherited"
            (ngModelChange)="toggleUseInherited($event)"
          ></p-radioButton>
          <label for="use-inherited">
            {{ 'geoLocation.input.use-inherited' | translate }}
          </label>
        </div>
        <div class="labeled-element">
          <p-radioButton
            inputId="edit-address"
            [value]="false"
            [(ngModel)]="edit.useInherited"
            (ngModelChange)="toggleUseInherited($event)"
          ></p-radioButton>
          <label for="edit-address">
            {{ 'geoLocation.input.edit-address' | translate }}
          </label>
        </div>
      </div>
      <app-text-field
        label="{{ 'geoLocation.input.address1' | translate }}"
        [(ngModel)]="edit.address1"
        [disabled]="edit.useInherited()"
      ></app-text-field>
      <app-text-field
        label="{{ 'geoLocation.input.address2' | translate }}"
        [(ngModel)]="edit.address2"
        [disabled]="edit.useInherited()"
      ></app-text-field>
      <app-text-field
        label="{{ 'geoLocation.input.zipCode' | translate }}"
        [(ngModel)]="edit.zipCode"
        [disabled]="edit.useInherited()"
        [required]="true"
      ></app-text-field>
      <app-text-field
        label="{{ 'geoLocation.input.city' | translate }}"
        [(ngModel)]="edit.city"
        [disabled]="edit.useInherited()"
        [required]="true"
      ></app-text-field>
      <p-autoComplete
        id="country"
        [dropdown]="true"
        [placeholder]="'geoLocation.input.country' | translate"
        [suggestions]="filteredCountries()"
        (completeMethod)="filterCountry($event)"
        [forceSelection]="true"
        field="label"
        [(ngModel)]="edit.country"
        [disabled]="edit.useInherited()"
        [required]="true"
      >
      </p-autoComplete>
    </div>
    <!-- right panel: map  -->
    <div class="right">
      <app-asset-heremap [narrow]="narrow()" [markers]="mapMarkers()"></app-asset-heremap>
      <button
        pButton
        type="button"
        class="ui-button-outline"
        label="{{ 'geoLocation.button.update-map' | translate }}"
        (click)="updateMap()"
        [disabled]="!updateMapEnabled()"
      ></button>
    </div>
  </div>
</div>

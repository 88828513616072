import { Component, input, model } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ToggleSwitchModule } from 'primeng/toggleswitch';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  standalone: true,
  imports: [FormsModule, ToggleSwitchModule],
})
export class InputSwitchComponent {
  value = model.required<boolean>();
  label = input.required<string>();
  disabled = input(false);
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DatePickerModule } from 'primeng/datepicker';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { SelectModule } from 'primeng/select';
import { DynamicDialog } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TextareaModule } from 'primeng/textarea';
import { ListboxModule } from 'primeng/listbox';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayModule } from 'primeng/overlay';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SkeletonModule } from 'primeng/skeleton';
import { SliderModule } from 'primeng/slider';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabsModule } from 'primeng/tabs';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { providePrimeNG } from 'primeng/config';
import Aura from '@primeng/themes/aura';
import { definePreset } from '@primeng/themes';

const sickTheme = definePreset(Aura, {
  components: {
    datepicker: {
      date: {
        borderRadius: '0',
      },
    },
    datatable: {
      drop: {
        point: {
          color: 'var(--syn-focus-ring-color)',
        },
      },
    },
  },
  primitive: {
    borderRadius: {
      lg: '0',
      md: '0',
      sm: '0',
      xl: '0',
      xs: '0',
    },
  },
  semantic: {
    transitionDuration: '0',
    focusRing: {
      color: 'var(--syn-focus-ring-color)',
      offset: 'var(--syn-focus-ring-offset)',
      width: 'var(--syn-focus-ring-width)',
    },
  },
});

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [
    AccordionModule,
    TableModule,
    SelectButtonModule,
    FileUploadModule,
    InputTextModule,
    TextareaModule,
    ToggleSwitchModule,
    ProgressBarModule,
    AutoCompleteModule,
    SelectModule,
    CheckboxModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    MultiSelectModule,
    RatingModule,
    DynamicDialog,
    TabsModule,
    ButtonModule,
    ToastModule,
    DatePickerModule,
    MessageModule,
    DialogModule,
    TooltipModule,
    FieldsetModule,
    ConfirmDialogModule,
    BreadcrumbModule,
    ChipModule,
    SplitterModule,
    PaginatorModule,
    SkeletonModule,
    ListboxModule,
    ChartModule,
    SliderModule,
    TreeModule,
    OverlayModule,
    BlockUIModule,
    InputNumberModule,
  ],
  providers: [
    providePrimeNG({
      theme: {
        preset: sickTheme,
        options: {
          cssLayer: {
            name: 'primeui',
            order: 'reset, primeui',
          },
          darkModeSelector: false,
        },
      },
    }),
  ],
})
export class PrimengModule {}
